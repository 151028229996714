import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useWindowHeight } from '@react-hook/window-size';
import { IconArrowRight } from '@frameio/vapor-icons';
import Layout from '../components/layout';
import breakpoints from '../utils/breakpoints';

const browser = typeof window !== 'undefined' && window;

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: 'white',
});

const Block404 = styled.div(({ windowHeight }) => {
  let isShort = false;

  if (windowHeight < 1000) {
    isShort = true;
  }

  return {
    [breakpoints.xs]: {
      height: `${isShort ? 'calc(100vh - 200px)' : 'calc(100vh - 789px)'}`,
    },
    [breakpoints.sm]: {
      height: 'calc(100vh - 625px)',
    },
    [breakpoints.md]: {
      height: 'calc(100vh - 479px)',
    },
  };
});

const InnerContainer = styled.main({
  width: '75%',
  margin: 'auto',
  height: '100%',
});

const Content = styled.div({
  display: 'flex',
  height: '80%',
  flexGrow: 3,
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  [breakpoints.xs]: {
    h1: {
      margin: '30px 0',
      fontSize: '96px',
    },
    h2: {
      margin: '10px 0 30px 0',
      fontSize: '36px',
    },
  },
  [breakpoints.sm]: {
    h1: {
      margin: '50px 0',
      fontSize: '200px',
      lineHeight: '1',
      fontWeight: 700,
      background: `linear-gradient(to bottom, #323745 20%, black 80%)`,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
    h2: {
      margin: '10px 0 30px 0',
      fontSize: '48px',
    },
  },
});

const GoHome = styled.div({
  padding: '24px',
});

const StyledLink = styled(Link)({
  outline: 'none',
  fontSize: '30px',
  color: '#000',
  textDecoration: 'none',
  ':hover': {
    color: 'rgba(0,0,0, 0.5)',
  },
});

const PageNotFound = () => {
  const windowHeight = useWindowHeight();

  return (
    browser && (
      <Layout>
        <Container>
          <Block404 windowHeight={windowHeight}>
            <InnerContainer>
              <Content>
                <h1>404</h1>
                <h2>Page not found</h2>
                <GoHome>
                  <StyledLink to="/">
                    Go home <IconArrowRight />
                  </StyledLink>
                </GoHome>
              </Content>
            </InnerContainer>
          </Block404>
        </Container>
      </Layout>
    )
  );
};

Block404.propTypes = {
  windowHeight: PropTypes.number.isRequired,
};

export default PageNotFound;
